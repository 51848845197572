import { theme, toRem } from '../../styles';
import styled from 'styled-components';

export const StyledDiv = styled('div')<{ isGiPage: boolean; dateLength?: number; stayDatesWidthRequired?: boolean }>(
  ({ isGiPage, dateLength, stayDatesWidthRequired }) => `
  &.stay-dates-container {
    flex: 1 1 46%;
  }
  .label {
    text-transform: uppercase;
    color: ${theme.colors['dark-gray-3']};
    line-height: ${toRem(16)};
    letter-spacing: ${toRem(1.3)};
    margin-bottom: ${toRem(5)};
    padding-bottom: ${toRem(8)};
  }
  .stay-dates {
    font-size: ${
      dateLength && dateLength > 22
        ? toRem(13)
        : dateLength && dateLength <= 22 && dateLength > 18
        ? toRem(15)
        : toRem(18)
    };
    line-height: ${toRem(24)};
  }

  .stay-total {
    font-size: ${toRem(18)};
    line-height: ${toRem(24)};
  }

  @media ${theme.mediaQuery.largeDesktop} {
    .stay-dates {
      width: ${stayDatesWidthRequired ? 'max-content' : 'none'} ;
    }
  }
  .currency,
  .points {
    line-height: ${toRem(14)};
    text-transform: uppercase;
  }
  .divider {
    &:dir(ltr) {
      border-right: ${toRem(2)} dashed ${theme.brand['brand-accent-color']};
    }
    margin: 0 ${!isGiPage ? toRem(50) : toRem(32)};
  }
  .edit-details {
    font-size: ${toRem(18)};
    font-weight: 100;
    color: ${theme.colors.black};
  }
  .icon-edit::before {
    margin-right: ${toRem(10.5)};
  }
  .edit-button {
    margin-left: ${toRem(32)};
    margin-top: ${toRem(20)};
    margin-bottom: ${toRem(4)};
  }
  .total-points{
    word-break:break-word;
  }
  `
);
