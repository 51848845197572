import { constants } from '../../constants';

export const replaceMiniCardWithTIBanner = () => {
  const resConfoTICard = document.getElementById(constants.RESCONFO_TI_BANNER);
  if (resConfoTICard) {
    const parentElem = resConfoTICard?.parentElement?.parentElement;
    const firstMiniCard = parentElem?.querySelector(`#${constants.MINICARD}`);
    firstMiniCard?.classList.add('d-none');
    resConfoTICard?.classList.remove('d-none');
  }
};

// get HTML attributes for the card
export const getMiniCardAttribute = (type = '', cardCtaLink = '', ctaLink = '', tiDeepLink = '') => {
  if (type === constants.MINICARD || type === constants.BONVOY_BANNER) return cardCtaLink;
  if (type === constants.RESCONFO_TI_BANNER && tiDeepLink) return tiDeepLink;
  if (type === constants.RESCONFO_TI_BANNER && !tiDeepLink) return cardCtaLink;
  return ctaLink;
};
