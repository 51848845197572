import { FC } from 'react';

import { Image } from '../../atoms';
import { messageCardProps } from './MessageCard.types';
import { StyledSection, StyledIcon, ImageContainer } from './MessageCard.styles';

import { createImageSizes } from '../../utils';
import { URL_CONSTANTS } from '../../constants';

const iconNameMap: Record<string, string> = {
  error: 'icon-warning',
  ocjError: 'icon-warning',
  info: 'icon-information',
  success: 'icon-success',
  alert: 'icon-alert',
  prefError: 'icon-warning',
  successMessage: 'icon-success',
  accessibilityAlerts: 'icon-accessibility',
  successMsg: 'icon-success',
  cancelSuccessMsg: 'icon-success',
  redemptionError: 'icon-alert',
  isAdultOnlyError: 'icon-alert',
  guestCountError: 'icon-alert',
  priceUpdateAlert: 'icon-warning',
  roomUpdateAlert: 'icon-warning',
  upcomingResAlert: 'icon-alert',
  redemptionErrorMsg: 'icon-warning',
  restrictModification: 'icon-warning',
  creditCardAlert: 'icon-alert',
  creditCardAlertRRD: 'icon-alert',
  warning: 'icon-warning',
  availabilityError: 'icon-warning',
  cancelOTP: 'icon-warning',
  prearrivalVerification: 'icon-warning',
};
const classNameMap: Record<string, string> = {
  error: 'error',
  ocjError: 'ocj-error',
  info: 'information',
  success: 'success',
  alert: 'alert',
  alertError: 'alert-error',
  hotelmessage: 'hotel-message',
  prefError: 'pref-error',
  successMessage: 'success-message',
  accessibilityAlerts: 'accessibility-alert',
  graphQlError: 'alert-error mt-5 mb-0',
  successMsg: 'success-msg',
  cancelSuccessMsg: 'cancel-success-msg',
  failureMessage: 'failure-message',
  redemptionError: 'no-redemption-error',
  isAdultOnlyError: 'is-adult-error',
  guestCountError: 'is-adult-error',
  priceUpdateAlert: 'warning-msg',
  roomUpdateAlert: 'warning-msg',
  upcomingResAlert: 'alert upcomingres-alert',
  redemptionErrorMsg: 'redemption-error-msg',
  restrictModification: 'modify-restrict',
  creditCardAlert: 'alert credit-card-alert mt-4 mb-5',
  creditCardAlertRRD: 'alert credit-card-alert mb-5',
  warning: 'warning-msg',
  availabilityError: 'warning-msg',
  cancelOTP: 'error cancel-otp',
  prearrivalVerification: 'prearrival-verification',
};

export const MessageCard: FC<messageCardProps> = ({
  type,
  messages,
  showCard,
  heading,
  fileReferenceDynamic,
  hideIcon,
  isLinkRequired = false,
  linkTitle = '',
  showModal = false,
  ariaLabel,
  setOpenModal,
  ariaLive,
  role,
}: messageCardProps) => {
  const iconName = iconNameMap[type] || 'icon-information';
  const className = classNameMap[type];
  const renditions = fileReferenceDynamic?.renditions;
  const imageSize = createImageSizes(renditions);

  return showCard ? (
    <StyledSection
      data-component-name="m-book-MessageCard"
      data-testid="book-MessageCard"
      id={type}
      className={className}
      {...(ariaLive && { 'aria-live': ariaLive })}
      {...(role && { role: role })}
    >
      {fileReferenceDynamic?.damPath && (
        <ImageContainer>
          <Image
            url={fileReferenceDynamic?.damPath}
            renditions={fileReferenceDynamic?.renditions}
            dynamic={fileReferenceDynamic?.dynamic}
            size={imageSize}
            appliedClass={'image-view'}
          />
        </ImageContainer>
      )}
      {!hideIcon && <StyledIcon className={[iconName, 'hide-icon'].join(' ')} />}
      <span>
        {heading && <span className="messagecard-heading">{heading}</span>}
        {messages.map(({ text, isRichText }, index) =>
          type === 'error' || type === 'ocjError' || isRichText ? (
            <div
              key={index.toString() + 'messageCardRichText'}
              data-rte-editelement
              className="message"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          ) : (
            <p className="message" key={index.toString() + 'messageCard'}>
              {text}
              {isLinkRequired &&
                (showModal ? (
                  setOpenModal && (
                    <a href="#" className="linktitle ml-1 t-font-weight-r" onClick={() => setOpenModal(true)}>
                      {linkTitle}
                    </a>
                  )
                ) : (
                  <a
                    href={URL_CONSTANTS.TI_REDIRECT_STATIC_LINK}
                    className="standard d-inline-block"
                    target="_blank"
                    rel="noreferrer"
                    aria-label={ariaLabel?.replace('{XX}', linkTitle)}
                  >
                    <span className="linktitle">{linkTitle}</span>
                    <span className="icon-external-arrow"></span>
                  </a>
                ))}
            </p>
          )
        )}
      </span>
    </StyledSection>
  ) : null;
};
