import styled from 'styled-components';
import { toRem, theme } from '../../styles';

export const StyledSection = styled.section`
  header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${theme.colors['close-silver']};
    padding: ${toRem(45)} ${toRem(32)} ${toRem(32.5)} ${toRem(40)};

    @media ${theme.mediaQuery.allMobiles} {
      padding: ${toRem(24)} ${toRem(29)};
    }
  }

  .rrd-header {
    padding: ${toRem(32)} ${toRem(31)} ${toRem(32)} ${toRem(16)};
    @media ${theme.mediaQuery.allMobiles} {
      padding: ${toRem(32)} ${toRem(31)} ${toRem(32)} ${toRem(16)};
    }
  }

  .room-request-header,
  .sna-alert-header {
    border-radius: ${toRem(14)} ${toRem(14)} 0 0;
    background-color: ${theme.colors.white};
    padding: ${toRem(31.5)} ${toRem(24)} ${toRem(29)} ${toRem(16)};

    @media ${theme.mediaQuery.allMobiles} {
      border-radius: ${toRem(14)} ${toRem(14)} 0 0;
      background-color: ${theme.colors.white};
      padding: ${toRem(31.5)} ${toRem(24)} ${toRem(29)} ${toRem(16)};
    }
  }

  .sna-alert-header {
    padding: ${toRem(28)} ${toRem(32)} ${toRem(26)} ${toRem(16)};
    .heading {
      line-height: ${toRem(28)};
    }
    @media ${theme.mediaQuery.desktop} {
      .heading {
        font-size: ${toRem(30)};
        line-height: ${toRem(36)};
      }
    }
    @media ${theme.mediaQuery.allMobiles} {
      padding: ${toRem(38)} ${toRem(24)} ${toRem(24)} ${toRem(8)};
      .heading {
        line-height: ${theme.fonts.lineHeightL};
      }
    }
  }

  .member-info-header {
    border-radius: ${toRem(14)} ${toRem(14)} 0 0;
    background-color: ${theme.colors.white};
    padding: ${toRem(31)} ${toRem(24)} ${toRem(30)};
  }

  .email-header {
    padding: ${toRem(32)} ${toRem(33)};

    @media ${theme.mediaQuery.allMobiles} {
      padding: ${toRem(24)} ${toRem(29)};
    }
  }

  .aaa-rate-header,
  .resort-fee-header {
    padding: ${toRem(32)} ${toRem(32)} ${toRem(27)} ${toRem(16)};
    border-radius: ${toRem(14)} ${toRem(14)} 0 0;
    background-color: ${theme.colors.white};
    .heading {
      font-size: ${theme.fonts['fontXxl']};
      line-height: ${theme.fonts['lineHeightXxl']};
    }
    @media ${theme.mediaQuery.allMobiles} {
      padding: ${toRem(36)} ${toRem(29)} ${toRem(19)} ${toRem(13)};
      .heading {
        width: 70%;
      }
    }
  }

  .resort-fee-header {
    @media ${theme.mediaQuery.allMobiles} {
      .heading {
        font-size: ${toRem(18)};
        line-height: ${toRem(20)};
      }
    }
  }

  .heading {
    margin: 0;
    color: ${theme.colors['base-10']};
    font-size: ${theme.fonts['fontXxl']};
    font-weight: ${theme.fonts['fontWeightMedium']};
    letter-spacing: 0;
    line-height: ${theme.fonts['lineHeightXxl']};
    padding-left: ${toRem(16)};
    @media ${theme.mediaQuery.allMobiles} {
      font-size: ${theme.fonts['fontL']};
      line-height: ${theme.fonts['lineHeightM']};
      width: 80%;
    }
  }

  .confirm-changes-header {
    padding: ${toRem(32)} ${toRem(31)} ${toRem(32)} ${toRem(16)};
    @media ${theme.mediaQuery.allMobiles} {
      padding: ${toRem(29)} ${toRem(24)} ${toRem(29)} ${toRem(8)};
      .heading {
        line-height: ${toRem(22)};
      }
    }
  }

  .rs-brand-modal-header {
    .heading {
      padding: 0;
      line-height: ${toRem(27.5)};
    }
    padding: ${toRem(30)} ${toRem(32)};
    @media ${theme.mediaQuery.allMobiles} {
      padding: ${toRem(32)} ${toRem(24)};
      .heading {
        font-size: ${toRem(18)};
        line-height: ${toRem(22.5)};
      }
    }
  }

  .empty-header {
    min-height: ${toRem(64)};
  }

  .back-arrow {
    background: none;
    color: ${theme.colors['base-10']};
    &:focus-visible {
      border: ${toRem(2)} solid ${theme.colors.black};
      border-radius: ${toRem(4)};
    }
  }

  .back-button {
    display: none;
    border-radius: ${toRem(4)};
    background-color: ${theme.colors.gallery};
    padding: ${toRem(4)} ${toRem(7)} 0;
    @media ${theme.mediaQuery.allMobiles} {
      display: inline-block;
    }
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 ${toRem(32)};
    .row1 {
      flex-wrap: nowrap;
    }
    li:last-child {
      border-bottom: none;
    }
    .row {
      flex: 1;
      justify-content: space-between;
      align-items: center;
      padding: ${toRem(22)} 0;
      border-bottom: ${toRem(1)} solid ${theme.colors['close-silver']};
      margin: 0;

      &.cursor {
        cursor: pointer;
      }

      .option {
        p {
          margin: 0;
        }
        display: flex;
        align-items: center;
        margin: 0;
        .icon-container {
          height: ${toRem(44)};
          width: ${toRem(44)};
          border-radius: 50%;
          border: ${toRem(1)} solid ${theme.colors['dark-gray-3']};
          .icons {
            position: relative;
            left: ${toRem(13)};
            top: ${toRem(8)};
          }
        }
      }

      .icon-label {
        color: ${theme.colors['base-10']};
        font-size: ${theme.fonts['fontL']};
        font-weight: ${theme.fonts['fontWeightMedium']};
        letter-spacing: 0;
        line-height: ${theme.fonts['lineHeightM']};
        padding-left: ${toRem(24)};
        @media ${theme.mediaQuery.allMobiles} {
          padding-left: ${toRem(18)};
        }
      }
      .button-container {
        min-height: ${toRem(40)};
        padding-top: ${toRem(8)};
      }
      .button-label {
        font-size: ${theme.fonts['fontM']};
        font-weight: ${theme.fonts['fontWeightMedium']};
        line-height: ${theme.fonts['lineHeightL']};
        padding: 0 ${toRem(32)};
      }
      @media ${theme.mediaQuery.mobileOnly} {
        .button-container {
          min-height: inherit;
          padding-top: ${toRem(6)};
        }
        .button-label {
          font-size: ${toRem(13)};
          line-height: ${theme.fonts['lineHeightXs']};
          padding: 0 ${toRem(8)};
          min-height: inherit;
        }
      }

      .buttons {
        padding-right: ${toRem(8)};
        color: ${theme.colors['dark-gray-3']};
      }
    }
  }
`;
