import styled from 'styled-components';
import { theme, toRem } from '../../styles';

export const StyledContainer = styled.section`
  display: inline-block;
  a.upside-arrow {
    &:hover,
    &:focus,
    &.focus-visible {
      &::after {
        font-family: MiIcons;
        content: ${`'\\e966'`};
        display: inline-block;
        transform: rotate(45deg);
        transition: 0.15s all ease-in;
      }
      &::before {
        display: none;
      }
    }
  }
  button.primary-nobrand {
    border-color: ${theme.colors['base-10']};
    background-color: ${theme.colors['base-10']};
    &:focus,
    &:hover {
      border-color: ${theme.colors['base-10']};
    }
  }
  @keyframes spinner-grow {
    0% {
      transform: scale(0);
    }
    50% {
      opacity: 1;
      transform: none;
    }
  }
  .btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .hide-title {
      visibility: hidden;
      position: absolute;
    }
    div {
      animation: 1s linear normal spinner-grow;
      .m-spinner {
        border-color: ${theme.colors.black};
        border-top-color: transparent;
      }
    }
  }
  /* button:disabled,
  button[disabled] {
    cursor: not-allowed;
    background-color: ${theme.colors['close-silver']};
    border: ${toRem(1)} solid ${theme.colors['close-silver']};
  } */
`;
