import { FC } from 'react';
import clsx from 'clsx';

import { StyledRadioButtonDiv } from './index.styles';
import { RadioButtonProps } from './index.types';

export const RadioButton: FC<RadioButtonProps> = ({
  className,
  radiobuttonId,
  radiobuttonName,
  radiobuttonLabel,
  checked,
  value,
  onChange,
  disabled,
  setTabIndex,
  customClickClass,
  isRichText,
  labelClassName,
  ...props
}) => {
  return (
    <StyledRadioButtonDiv className={className} tabIndex={disabled ? -1 : setTabIndex}>
      <input
        type="radio"
        id={radiobuttonId}
        name={radiobuttonName}
        onChange={onChange}
        checked={checked}
        value={value}
        disabled={disabled}
        role="radio"
        tabIndex={-1}
        className={customClickClass}
        {...props}
      />
      {isRichText ? (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label
          className={clsx(labelClassName ? labelClassName : '')}
          htmlFor={radiobuttonId}
          dangerouslySetInnerHTML={{ __html: radiobuttonLabel ?? '' }}
        />
      ) : (
        <label className={clsx(labelClassName ? labelClassName : '')} htmlFor={radiobuttonId}>
          {radiobuttonLabel}
        </label>
      )}
    </StyledRadioButtonDiv>
  );
};
export default RadioButton;
