import { FC } from 'react';
import { isCNLocale as isCN } from '@marriott/shared/mi-helper-utils';
import { constants } from '../../constants/constants';
import { SaveReservationAries } from './SaveReservationAries';
import { SaveReservationPhoenix } from './SaveReservationPHX';
import { ReservationModalProps } from './SaveReservation.types';
import { getOCJModalPlatform, getWindowSession } from '../../utils/helper';

export const SaveReservation: FC<ReservationModalProps> = props => {
  const { modalType = '' } = props;
  const sessionObject = getWindowSession();
  const isCNLocale = isCN(sessionObject?.locale);
  const platform = getOCJModalPlatform(modalType);
  if (isCNLocale) {
    return null;
  }
  if (platform === constants.PHOENIX) {
    return <SaveReservationPhoenix {...props} />;
  }
  return <SaveReservationAries {...props} />;
};
