import dynamic from 'next/dynamic';
import { RoomImageCarouselProps } from './RoomImageCarousel.types';
import Skeleton from 'react-loading-skeleton';
import { caraouselSizeForRoomImages } from './RoomImageCarousel.config';
import { canUseDOM, getViewPortValue } from '../../utils/helper';

export const RoomImageCarousel = dynamic<RoomImageCarouselProps>(() => import('./RoomImageCarousel'), {
  loading: () => {
    const viewPortValue = canUseDOM ? getViewPortValue() : 'desktop';
    const caraouselSize = caraouselSizeForRoomImages?.[viewPortValue];
    return <Skeleton width={caraouselSize?.width} height={caraouselSize?.height} />;
  },
});
