import { FC, KeyboardEvent } from 'react';
import cssClass from 'classnames';

import { ModalUI } from '../Modal';
import { CustomModalProps, OptionsListProps } from './CustomModal.types';
import { StyledSection } from './CustomModal.styles';

export const CustomModal: FC<CustomModalProps> = props => {
  const {
    openModal,
    optionsList,
    heading,
    backArrow,
    handleBackButton,
    handleClose,
    handleListClick,
    handleButtonClick,
    optionsModal,
    primaryButtonLabel,
    children,
    styledClassName = '',
    showHeader = true,
    isShareEmail = false,
    isRRDHeader = false,
    isSOCPopup = false,
    isRoomRequestHeader = false,
    isPolicyHeader = false,
    isFilterPopup = false,
    isAAARateHeader = false,
    isResortFeeHeader = false,
    keepMounted = true,
    ariaDescribedby,
    isMemberInfoHeader = false,
    isSnaAlertHeader = false,
    isConfirmChangesHeader = false,
    isDialog = true,
    isRSBrandModal = false,
    isEmptyHeader = false,
  } = props;

  const backButtonClass = isPolicyHeader && 'back-button';
  const isSpecialPopup =
    isRRDHeader ||
    isRoomRequestHeader ||
    isAAARateHeader ||
    isMemberInfoHeader ||
    isResortFeeHeader ||
    isSnaAlertHeader;

  const customModalClassName = cssClass(`custom ${styledClassName}`, {
    'share-email-header': isShareEmail,
    'custom-popup': backArrow && showHeader,
    'custom-popup-soc': isSOCPopup,
    'hide-close': isPolicyHeader,
    'filters-custom-popup': isFilterPopup,
    'special-popup': isSpecialPopup,
    'rs-brand-modal': isRSBrandModal,
  });

  /*Generating css classname from classname module*/
  const headerClassName = cssClass({
    'rrd-header': isRRDHeader,
    'room-request-header': isRoomRequestHeader,
    'email-header': isShareEmail,
    'aaa-rate-header': isAAARateHeader,
    'resort-fee-header': isResortFeeHeader,
    'member-info-header': isMemberInfoHeader,
    'sna-alert-header': isSnaAlertHeader,
    'confirm-changes-header': isConfirmChangesHeader,
    'rs-brand-modal-header': isRSBrandModal,
    'empty-header': isEmptyHeader,
  });
  const listItemClassName = cssClass('row custom_click_track', { cursor: !primaryButtonLabel });

  const handleKeyDownListClick = (e: KeyboardEvent, key: string) => e.keyCode === 13 && handleListClick?.(key);

  const renderChildren = () => {
    return (
      <StyledSection aria-live={optionsModal ? 'polite' : 'off'}>
        {showHeader && (
          <header className={headerClassName}>
            {backArrow && renderBackArrow()}
            {heading && <h1 className="heading">{heading}</h1>}
          </header>
        )}
        {optionsModal ? (
          <section className="content-wrapper" role="list">
            {renderContent()}
          </section>
        ) : (
          children
        )}
      </StyledSection>
    );
  };

  const renderBackArrow = () => {
    return (
      <button aria-label="Back Button" className={[backButtonClass, 'back-arrow'].join(' ')} onClick={handleBackButton}>
        <span className={'icon-arrow-left'} />
      </button>
    );
  };

  const renderContent = () => {
    return optionsList?.map(
      ({ key, iconClass, buttonClass, title, download, clickTrackValue, customCss }: OptionsListProps) => (
        <li
          className={listItemClassName + ' ' + (customCss ?? '')}
          key={key}
          onClick={!primaryButtonLabel ? () => handleListClick?.(key) : undefined}
          aria-label={key}
          tabIndex={0}
          role="listitem"
          onKeyDown={!primaryButtonLabel ? e => handleKeyDownListClick(e, key) : undefined}
          data-custom_click_track_value={!primaryButtonLabel && clickTrackValue}
        >
          <span className="option">
            <span className="icon-container">
              <span className={`${iconClass} icons`} />
            </span>
            <span className="icon-label">{title}</span>
          </span>
          {primaryButtonLabel ? (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              className="m-button-s m-button-primary button-container custom_click_track"
              id={key}
              download={download}
              onClick={() => handleButtonClick(key)}
              aria-label={key}
              tabIndex={0}
              data-custom_click_track_value={clickTrackValue}
            >
              <span className="button-label">{primaryButtonLabel}</span>
            </a>
          ) : (
            <span className={`${buttonClass} buttons`} />
          )}
        </li>
      )
    );
  };

  return (
    <div data-component-name="m-book-CustomModal" data-testid="book-CustomModal">
      <ModalUI
        open={openModal}
        onClose={handleClose}
        keepMounted={keepMounted}
        children={renderChildren()}
        variation="modal-design"
        customCss={customModalClassName}
        ariaDescribedby={ariaDescribedby}
        isDialog={isDialog}
      />
    </div>
  );
};
