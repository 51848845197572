export const caraouselSizeForRoomImages = {
  mobile: {
    width: '100%',
    height: '221px',
    imgBorderRadius: '14px 14px 0 0',
    imgBackgroundColor: '#000',
    imgObjectFit: 'none',
  },
  tablet: {
    width: '195px',
    height: '195px',
    imgBorderRadius: '14px 0 0 0px',
    imgBackgroundColor: '#000',
    imgObjectFit: 'none',
  },
  desktop: {
    width: '507px',
    height: '285px',
    imgBorderRadius: '14px 0 0 14px',
    imgBackgroundColor: '#000',
    imgObjectFit: 'none',
  },
  sizes: {
    desktop: '*:339',
    tablet: '*:220',
    mobile: '*:250',
  },
};

export const caraouselSizeForRoomDetails = {
  mobile: {
    width: '100%',
    height: '364px',
    imgBorderRadius: '0',
    imgBackgroundColor: '#000',
    imgObjectFit: 'none',
  },
  tablet: {
    width: '100%',
    height: '396px',
    imgBorderRadius: '0',
    imgBackgroundColor: '#000',
    imgObjectFit: 'none',
  },
  desktop: {
    width: '100%',
    height: '525px',
    imgBorderRadius: '0',
    imgBackgroundColor: '#000',
    imgObjectFit: 'none',
  },
  sizes: {
    largeDesktop: '*:645',
    desktop: '*:550',
    tablet: '*:448',
    mobile: '*:396',
  },
};
