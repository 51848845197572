import React, { useState, useRef, useEffect } from 'react';
import { DropdownContainer, DropdownButton, DropdownList, DropdownLabel } from './index.styles';
import RadioButton from '../../organisms/SearchForm/component/atoms/RadioButton';
import { ICurrencySelectorProps } from './index.types';
import { CustomModal } from '../CustomModal';
import { useScreenViewport, getCurrencySymbol } from '../../utils';
import { constants } from '../../constants';

export const CurrencySelector = ({ selectedCurrency, onChangeHandler, ...props }: ICurrencySelectorProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(selectedCurrency);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const isMobileView = useScreenViewport() === constants.MOBILE;

  const { modalTitle, dropdownLabel, currencies } = props;

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    if (value) {
      setSelected(value);
      onChangeHandler(value);
      setIsOpen(false);
    }
  };
  const handleDesktopClickOutside = (event: MouseEvent) => {
    if (!isMobileView && !dropdownRef?.current?.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (!isMobileView) {
      document.addEventListener('click', handleDesktopClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleDesktopClickOutside);
    };
  }, [isMobileView]);

  const DropdownListComp = () => {
    return (
      <DropdownList>
        <DropdownLabel>{dropdownLabel}</DropdownLabel>
        {currencies?.map((currency, index) => (
          <RadioButton
            key={index}
            radiobuttonId={`currencyCode_${index}`}
            className="radio-label custom-radio"
            radiobuttonName="currencyCode"
            radiobuttonLabel={currency.label}
            setTabIndex={0}
            checked={selected === currency.value}
            value={currency.value}
            onChange={event => handleOptionSelect(event)}
          />
        ))}
      </DropdownList>
    );
  };

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownButton onClick={toggleDropdown}>
        {selected ? `${getCurrencySymbol(selected)} ${selected}` : 'Default'}
        <span className={isOpen ? 'icon-dropdown-up' : 'icon-dropdown-down'} />
      </DropdownButton>
      {isMobileView ? (
        <CustomModal
          openModal={isOpen}
          heading={modalTitle}
          isEmptyHeader={true}
          handleClose={() => {
            setIsOpen(false);
          }}
          optionsModal={false}
          backArrow={false}
        >
          <DropdownListComp />
        </CustomModal>
      ) : (
        isOpen && <DropdownListComp />
      )}
    </DropdownContainer>
  );
};
