export const SessionDataMap = {
  'cacheData.data.AriesSearch.searchCriteria.availabilityRequestVO.lengthOfStay': 'lengthOfStay',
  'cacheData.data.AriesReservation.propertyId': 'propertyId',
  'cacheData.data.AriesReservation.ersProperty': 'isErs',
  'cacheData.data.AriesReservation.inAuthID': 'inAuthID',
  'cacheData.data.AriesSearch.searchCriteria.availabilityRequestVO.checkInDate': 'checkInDate',
  'cacheData.data.AriesSearch.searchCriteria.availabilityRequestVO.checkOutDate': 'checkOutDate',
  'cacheData.data.AriesSearch.searchCriteria.availabilityRequestVO.numRooms': 'numRooms',
  'cacheData.data.AriesCommon.memState': 'userState',
  'cacheData.data.consumerID': 'consumerID',
  'cacheData.data.travelAgentIataNumber': 'iataNumber',
  'cacheData.data.AriesSearch.searchCriteria.availabilityRequestVO.corporateCode': 'corporateCode',
  'cacheData.data.AriesSearch.searchCriteria.availabilityRequestVO.groupCode': 'groupCode',
  'cacheData.data.AriesReservation.confirmationNumber': 'confirmationNumber',
  'cacheData.data.AriesSearch.searchCriteria.showFullPrice': 'showFullPrice',
  'cacheData.data.AriesReservation.tripsXRequestedByHeader': 'tripsXRequestedByHeader',
  'cacheData.data.AriesSearch.searchCriteria.selectedCurrency': 'searchSelectedCurrency',
  'cacheData.data.AriesReservation.selectedCurrency': 'resSelectedCurrency',
  'cacheData.data.AriesReservation.propertyCurrency': 'propertyCurrency',
  locale: 'locale',
  authenticated: 'isAuth',
  'cacheData.data.AriesReservation.isMax': 'isMax',
};

export const ImagesDataMap = {
  hotelPhotoTours: 'hotelPhotoTours',
  'property.contactInformation.address.country.code': 'countryCode',
};
