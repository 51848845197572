import { Button } from '../../atoms';
import { StayDataProps } from './StayInfo.types';
import { StyledDiv } from './StayInfo.styles';
import cssClass from 'classnames';

import { constants, TRACKING_CONSTANT } from '../../constants';
import { getLocaleFromDocument } from '../../utils/helper';

const { HYBRID_PAGE } = constants;

export const StayInfo = (props: StayDataProps) => {
  const {
    stayData,
    isRedemption,
    classname,
    totalForStay,
    stayDates,
    points,
    totalStay = '',
    mobileStyle = '',
    isDesktopView = true,
    isGiPage = false,
    giToRLM = '',
    type = '',
    edit,
    total,
    isSticky,
    showInclusiveTerm,
    inclusiveTerm,
    isReservationSummary = false,
  } = props;
  const { startDate, endDate, currency, pointsAvailed, totalAmount, suppressRate } = stayData;
  const isHybridPage = type === HYBRID_PAGE;
  const { INTERNAL_LINK } = TRACKING_CONSTANT;

  const updatedCurrency = String(currency);
  const maxDateLength = Math.max(startDate ? startDate.toString().length : 0, endDate ? endDate.toString().length : 0);

  const stayDetailsCss = cssClass('mb-4', {
    ' mb-xl-4': isReservationSummary,
  });
  const endDateCss = cssClass('d-md-inline-block', {
    'd-inline-block': isReservationSummary,
  });
  return (
    <StyledDiv
      data-component-name="m-book-StayInfo"
      data-testid="book-StayInfo"
      isGiPage={isGiPage}
      dateLength={maxDateLength}
      className={classname}
      stayDatesWidthRequired={getLocaleFromDocument() !== constants.IS_LOCALE_VIETNAMESE}
    >
      <div className={`mb-4 mb-xl-0 ${stayDetailsCss} ${mobileStyle}`}>
        <span className="t-font-xs label">{stayDates}</span>
        <div className="stay-dates">
          {startDate} - <div className={endDateCss}>{endDate}</div>
        </div>
      </div>
      {isGiPage && isDesktopView && (
        <Button
          isLink={true}
          href={giToRLM}
          buttonClassName={'d-block edit-button'}
          className="icon-edit edit-details"
          children={edit?.ctaText}
          trackingProperties={edit?.trackingProperties}
          linkType={INTERNAL_LINK}
        />
      )}
      {!suppressRate && !isReservationSummary && <div className={`divider d-none d-xl-inline-block ${mobileStyle}`} />}
      {!suppressRate && (
        <div>
          <span className="t-font-xs label">
            {isDesktopView && !isHybridPage && !isGiPage ? totalForStay : totalStay}
          </span>
          <div className="stay-total">
            {Boolean(total && total > 0) && (
              <div className="d-inline-block">
                {total && <span className="amount">{totalAmount}</span>}
                <span className="currency t-font-xs"> {updatedCurrency?.getSymbol()}</span>
                {isRedemption && <span className="icon-plus t-font-xs mr-1 ml-1"></span>}
              </div>
            )}
            {!isSticky && totalAmount && (
              <div className="d-inline-block">
                <span className="amount">{totalAmount}</span>
                <span className="currency t-font-xs"> {updatedCurrency?.getSymbol()}</span>
                {isRedemption && <span className="icon-plus t-font-xs mr-1 ml-1"></span>}
              </div>
            )}
            {isRedemption && (
              <div className="d-inline-block">
                <span className="total-points">{pointsAvailed}</span>
                <span className="points t-font-xs ml-1">{points}</span>
              </div>
            )}
            <div className="d-inline-block">
              <span className="t-font-xs ml-1">{showInclusiveTerm ? ` ${inclusiveTerm}` : ''}</span>
            </div>
          </div>
        </div>
      )}
    </StyledDiv>
  );
};
