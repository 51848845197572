import dynamic from 'next/dynamic';
import { ComponentProps } from './ReservationSummary.types';

export const ReservationSummaryV1 = dynamic<ComponentProps>(() => import('./ReservationSummary'));
export const ReservationSummaryV2 = dynamic<ComponentProps>(() => import('./ReservationSummaryV2'));
// import('./ReservationSummary').then(module => module.ReservationSummary)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ReservationSummary = (props: any) => {
  if (props?.newResSummary) return <ReservationSummaryV2 {...props} />;
  return <ReservationSummaryV1 {...props} />;
};
