import { theme, toRem } from '../../styles';
import styled from 'styled-components';

export const DropdownContainer = styled.div`
  position: relative;
  text-align: right;
`;

export const DropdownButton = styled.button`
  padding: ${toRem(10)};
  padding-left: ${toRem(24)};
  background-color: transparent;
  color: ${theme.colors['base-10']};
  cursor: pointer;
`;

export const DropdownList = styled.ul`
  position: absolute;
  right: 0;
  width: min-content;
  background-color: ${theme.colors.white};
  border-radius: ${toRem(5)};
  padding: ${toRem(0)} ${toRem(25)};
  list-style: none;
  overflow-y: auto;
  box-shadow: 0 0 ${toRem(8)} ${toRem(6)} rgba(0, 0, 0, 0.1);
  z-index: 100;

  @media ${theme.mediaQuery.mobileOnly} {
    position: static;
    width: 100%;
    height: 100%;
    background-color: ${theme.colors.background};
    box-shadow: none;
  }
`;

export const DropdownLabel = styled.li`
  display: flex;
  font-size: ${toRem(16)};
  align-items: center;
  padding: ${toRem(16)} ${toRem(10)} ${toRem(12)};
  cursor: pointer;

  @media ${theme.mediaQuery.mobileOnly} {
    padding: ${toRem(20)};
  }
`;
